import _utils from "./utils";
import _bind from "./helpers/bind";
import _Axios from "./core/Axios";
import _defaults from "./defaults";
import _Cancel from "./cancel/Cancel";
import _CancelToken from "./cancel/CancelToken";
import _isCancel from "./cancel/isCancel";
import _spread from "./helpers/spread";
var exports = {};
var utils = _utils;
var bind = _bind;
var Axios = _Axios;
var defaults = _defaults;
/**
 * Create an instance of Axios
 *
 * @param {Object} defaultConfig The default config for the instance
 * @return {Axios} A new instance of Axios
 */

function createInstance(defaultConfig) {
  var context = new Axios(defaultConfig);
  var instance = bind(Axios.prototype.request, context); // Copy axios.prototype to instance

  utils.extend(instance, Axios.prototype, context); // Copy context to instance

  utils.extend(instance, context);
  return instance;
} // Create the default instance to be exported


var axios = createInstance(defaults); // Expose Axios class to allow class inheritance

axios.Axios = Axios; // Factory for creating new instances

axios.create = function create(instanceConfig) {
  return createInstance(utils.merge(defaults, instanceConfig));
}; // Expose Cancel & CancelToken


axios.Cancel = _Cancel;
axios.CancelToken = _CancelToken;
axios.isCancel = _isCancel; // Expose all/spread

axios.all = function all(promises) {
  return Promise.all(promises);
};

axios.spread = _spread;
exports = axios; // Allow use of default import syntax in TypeScript

exports.default = axios;
export default exports;